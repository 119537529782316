"use client";

import { motion } from "framer-motion";
import { usePathname } from "next/navigation";
import { Logo } from "../components/logo";
import { DashboardSectionProvider, type Section } from "../providers/dashboardSectionProvider";
import { DashboardHeader } from "../sections/dashboardHeader";
import { DashboardNavigation } from "../sections/dashboardNavigation";

interface NavGroup {
  title: string;
  links: Array<{
    title: string;
    href: string;
    type?: string;
  }>;
}

export const DashboardLayout = ({
  children,
  allSections,
  navigationMap,
  domainData,
  user,
  currentSession,
  showSettings,
  logout,
  role,
  redirectToHome,
}: {
  children: React.ReactNode;
  allSections: Record<string, Array<Section>>;
  navigationMap: Array<NavGroup>;
  domainData: any;
  user: any;
  currentSession: any;
  showSettings: () => void;
  logout: () => void;
  role: any;
  redirectToHome: () => void;
}) => {
  const pathname = usePathname() || "";

  return (
    <DashboardSectionProvider sections={allSections[pathname] ?? []}>
      <div className="h-full lg:ml-60">
        <motion.header layoutScroll className="contents lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex">
          <div className="contents lg:pointer-events-auto lg:block lg:w-60 lg:overflow-y-auto lg:border-r lg:border-zinc-900/10 lg:px-6 lg:pb-8 lg:pt-4 lg:dark:border-white/10 xl:w-60">
            <div className="hidden lg:flex">
              <Logo overrideLogoUrl={domainData.logo} role={role} />
            </div>
            <DashboardHeader
              navigationMap={navigationMap}
              domainData={domainData}
              user={user}
              showSettings={showSettings}
              logout={logout}
              currentSession={currentSession}
              redirectToHome={redirectToHome}
              role={role}
            />

            <DashboardNavigation
              className="font-secondary hidden lg:mt-10 lg:block"
              navigationMap={navigationMap}
              currentSession={currentSession}
              redirectToHome={redirectToHome}
              role={role}
            />
          </div>
        </motion.header>
        <main className="relative flex h-full flex-col px-2 pb-4 pt-16 sm:px-6 md:px-4 md:pb-8 lg:px-4">
          {children}
        </main>
      </div>
    </DashboardSectionProvider>
  );
};
