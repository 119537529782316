import { cn } from "@repo/utils";
import { useRouter } from "next/navigation";
import { useState } from "react";

interface LogoProps {
  altText?: string;
  overrideLogoUrl?: string;
  position?: string;
  role: string;
}

export const Logo = ({ altText, overrideLogoUrl, position, role }: LogoProps) => {
  const router = useRouter();
  const [isNavigating, setIsNavigating] = useState(false);

  const handleLogoClick = () => {
    const path = role === "Admin" ? "/admin/home" : "/home";
    if (isNavigating) return;
    setIsNavigating(true);
    try {
      router.push(path);
    } catch (error) {
      console.error("Navigation error:", error);
    } finally {
      setIsNavigating(false);
    }
  };

  const logo = overrideLogoUrl || "";
  const src = `data:image/png;base64,${logo}`;

  return (
    <div role="button">
      <img
        onClick={handleLogoClick}
        className={cn("h-10 w-auto cursor-pointer", position === "center" ? "mx-auto" : "mr-4")}
        src={src}
        width={100}
        height={120}
        alt={altText || "logo"}
        aria-label="Home"
      />
    </div>
  );
};
