"use client";

import { Button } from "@repo/ui";
import { cn } from "@repo/utils";
import { motion, useScroll, useTransform } from "framer-motion";
import { forwardRef } from "react";

import { Logo } from "../components/logo";
import {
  DashboardMobileNavigation,
  useIsInsideMobileNavigation,
  useMobileNavigationStore,
} from "./dashboardMobileNavigation";

interface NavGroup {
  title: string;
  links: Array<{
    title: string;
    href: string;
  }>;
}

export const DashboardHeader = forwardRef<
  React.ElementRef<"div">,
  {
    className?: string;
    navigationMap: Array<NavGroup>;
    domainData: any;
    user: any;
    showSettings: () => void;
    logout: () => void;
    currentSession: any;
    role: any;
    redirectToHome: () => void;
  }
>(function Header(
  { className, navigationMap, domainData, user, showSettings, logout, currentSession, redirectToHome },
  ref,
) {
  const { isOpen: mobileNavIsOpen } = useMobileNavigationStore();
  const isInsideMobileNavigation = useIsInsideMobileNavigation();

  const { scrollY } = useScroll();
  const bgOpacityLight = useTransform(scrollY, [0, 72], [0.5, 0.9]);
  const bgOpacityDark = useTransform(scrollY, [0, 72], [0.2, 0.8]);

  const role = user?.is_admin ? "Admin" : "User";
  return (
    <motion.div
      ref={ref}
      className={cn(
        className,
        "fixed inset-x-0 top-0 z-50 flex h-14 items-center justify-between gap-12 px-4 transition sm:px-6 lg:left-60 lg:z-30 lg:px-4 xl:left-60",
        !isInsideMobileNavigation && "backdrop-blur-sm dark:backdrop-blur lg:left-60 xl:left-60",
        isInsideMobileNavigation
          ? "bg-white dark:bg-zinc-900"
          : "bg-white/[var(--bg-opacity-light)] dark:bg-zinc-900/[var(--bg-opacity-dark)]",
      )}
      style={
        {
          "--bg-opacity-light": bgOpacityLight,
          "--bg-opacity-dark": bgOpacityDark,
        } as React.CSSProperties
      }
    >
      <div
        className={cn(
          "absolute inset-x-0 top-full h-px transition",
          (isInsideMobileNavigation || !mobileNavIsOpen) && "bg-zinc-900/7.5 dark:bg-white/7.5",
        )}
      />
      <div className="hidden lg:block lg:max-w-md lg:flex-auto" />

      <div className="flex items-center gap-5 lg:hidden">
        <DashboardMobileNavigation
          navigationMap={navigationMap}
          domainData={domainData}
          user={user}
          showSettings={showSettings}
          logout={logout}
          currentSession={currentSession}
          redirectToHome={redirectToHome}
          role={role}
        />
        <Logo overrideLogoUrl={domainData.logo} role={role} />
      </div>
      <div className="flex items-center gap-5">
        <div
          onClick={showSettings}
          className="-mx- relative mt-2 hidden cursor-pointer text-sm text-gray-400 hover:text-gray-500 md:block"
        >
          <span>{user?.email}</span>
          {role === "Admin" && (
            <span className="text-2x ml-2 inline-block rounded-full bg-indigo-200 px-1 text-white">Admin</span>
          )}
        </div>
        <div className="hidden md:block md:h-5 md:w-px md:bg-zinc-900/10 md:dark:bg-white/15" />

        <div className="hidden min-[416px]:contents">
          <Button variant="filled" className="ml-4" onClick={logout}>
            Log out
          </Button>
        </div>
      </div>
    </motion.div>
  );
});
